import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import MuiIcons from "../../ui/MuiIcons/MuiIcons";


const AccordionItem = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const AccordionItemTitle = styled.div`
  line-height: var(--global-line-height);
  box-shadow: var(--box-shadow-2);
  padding: 18px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.bg ?? "#fff"};
  color: ${(props) => (props.isOpen ? "var(--primary-color-1)" : "#000")};
  border-radius: ${(props) => (props.isOpen ? "6px 6px 0 0" : "5px")};
  font-weight: bold;
  transition: color 0.25s ease-in, border-radius 0.25s ease-in;
  font-size: var(--global-font-size);
  cursor: pointer;
  svg{font-size:30px;}
`;


const AccordionDetails = styled.div`
  width: 100%;
  background: ${(props) => props.bg ?? "#fff"};
  padding: ${(props) => (props.isOpen ? "12px 16px" : "0 16px")};
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? `${props.height}px` : "0px")};
  transition: max-height 0.4s ease-in-out, padding 0.3s ease-in-out;
`;

const Accordion = ({index, expanded, summary = "", details, onChange }) => {
  const isOpen = expanded === index;
   const contentRef=useRef(null);
   const [height, setHeight] = useState(0);

   useLayoutEffect(()=>{
    if(contentRef?.current && isOpen){
      setHeight(contentRef.current?.scrollHeight+50)
    }
   },[isOpen])

  return (
    <AccordionItem key={index}>
      <AccordionItemTitle onClick={() => onChange(index)} isOpen={isOpen}>
        <span>{summary}</span>
        <MuiIcons iconName={isOpen ? "minus" : "add"} />
      </AccordionItemTitle>
      <AccordionDetails isOpen={isOpen} ref={contentRef} height={height}  >{details}</AccordionDetails>
    </AccordionItem>
  );
};

export default Accordion;
