import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import '../../../assets/scss/accordion.scss'
import PropTypes from 'prop-types'
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';
import t from '../../../translation/useTranslate';
import Accordion from './SkillACC';
import styled from 'styled-components';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const ImageContainer=styled.div`
display:flex;
flex-direction:${(props)=>props?.direction}
`

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

/**
 * Accordion can display the different content as each topic 
*/

const TopicAccordion = ({ panelList, track, handler, cename, instructions,has_instruction }) => {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [viewedList, setViewedList] = useState([])
    const [instruction, setInstruct] = useState({
            text: t("ACCORDION_INFO"),
            enabled: true
    })
    const [status, setStatus] = useState({completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE})

    /**
     * 
     * @param {*} panel 
     * panellist and clicked length equal then change the status 1 
     * as completed
     */
    const handleChange = (panel) => {
        setExpanded((previous)=>previous === panel ? null : panel);

        /**progress tracking start*/
        if (viewedList.indexOf(panel) === -1) {
            viewedList.push(panel)
        }
        setViewedList([...viewedList]);

        updateProgress(panel)
        /** progress update end */
    };
    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
    const updateProgress = (currentItem) => {

        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c: currentItem }
        /**
         * Progress updated as and when its completed
         */
        if (!track.status) {
            track.status = (viewedList.length === panelList.length) ? 1 : 0;
            if (track.status) {
                setInstruct({
                    ...instruction,
                    text:t("LRN_COMPLETED_INFO"),
                    className: 'completed'
                })
                setStatus({completed: true, text:t("LRN_COMPLETED_INFO")})
                handler({ type: SAVE_PROGRESS_STATUS, id:track.id, name: cename })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
         handler({ type: UPDATE_STATUS, id:track.id, name: cename})
         
    }

    const getImagePath = (path)=>{
        return DataHelper.getResourcePath(0,path);
    }

    return (
        <>
        <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={instruction?.text}/>
        <div className={classes.root}>
            {
                (panelList?.length > 0) && panelList?.map((el,index) => {
                    const direction=textPositions[el?.textPosition ?? 'column'][el?.imgPosition ?? 'center'] || 'column'
                    return <Accordion
                    onChange={()=>handleChange(index)}
                    index={index}
                    summary={el.title}
                    expanded={expanded}
                    details={accordionDetails(direction, el, getImagePath)}
                    />
                })
            }

        </div>
        </>
    );
}

TopicAccordion.defaultProps = {
    instructions: {
        text: t("ACCORDION_INFO"),
        enabled: true
    }
}

export const textPositions={
    'row':{
        'flex-start':'row',
        'center':'row',
        'flex-end':'row-reverse'
    },
    'column':{
        'column':'column'
    }
}

TopicAccordion.propTypes = {
    /** PanelList contains array of content text area */
    panelList: PropTypes.array.isRequired,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func
}

export default TopicAccordion;

function accordionDetails(direction, el, getImagePath) {
    return <>
        <ImageContainer className='accordion-image' direction={direction}>
            <div className='img'>
                {el?.imgUrl && <img src={getImagePath(el?.imgUrl)} loading='lazy' />}
                <div className="layer" style={{ backgroundColor: el?.styles?.color || "#0000" }} />
            </div>
            <Typography component="p" dangerouslySetInnerHTML={{ __html: el.description }} />
        </ImageContainer>

    </>;
}
