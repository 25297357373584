import React, { useEffect,useRef } from 'react'
import useForceUpdate from 'use-force-update';
import { AddCircle } from '@material-ui/icons';
import PropTypes from 'prop-types'
import PlayerConstants from '../../../utils/PlayerConstants';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import Datahelper from '../../../utils/DataHelper'
import UploaderPanel from '../UploaderPanel/UploaderPanel';
import { FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';
import ACLHelper from '../../../utils/ACLHelper';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Checkbox from '@mui/material/Checkbox';

/**
 * 
 * Accordion editor panel used to modify the propertis of Accordion component
 * 
 * handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
 */

const AccordionPanel = ( { panelList, handleProperties, cename, id, handleCeAssets} ) => {
    const forceUpdate = useForceUpdate()
    const [ expanded, setExpanded ] = React.useState( null );
    const [ editMode, setEdit ] = React.useState( null );
    const [ list, setList ] = React.useState(panelList)
    const {showWarning} = useToastNotifications();
    const [drag, setDrag] =  React.useState(false);

    let max_count = 0
    const getMaxPanel = Object.values( PlayerConstants.COMPONENTS_LIST ).filter( ( el ) => el.name === cename )
    max_count = getMaxPanel[ 0 ].max_count;

    const handleChange = ( panel ) => ( event, isExpanded ) => {
        setExpanded( isExpanded ? panel : false );
        setEdit( null )
    };

    useEffect(()=>{
        handleProperties({ panelList: list });
    },[list])

    const handleChangeInput = ( e, id ) => {
        list[ id ][ e.target.name ] = e.target.value
        setList( [...list] );
    }

    const handleEdit = ( e, index ) => {
        setEdit( index )
        e.stopPropagation()
    }

    const handleSave = ( e, index ) => {
        setEdit( null )
        e.stopPropagation()
    }

    const handleDelete = ( e, index ) => {
        if(list.length > 1){
            const removed = list.filter( ( e, i ) => i !== index )
            setList( [...removed] )
            return;
        }
        showWarning("Minimum item limit reached. No further deletions allowed");
        e.stopPropagation()
    }

    const addAccordion = () => {
        if ( max_count > list.length ) {
            const maxId = Math.max(...list?.map(item => item?.id), 0)||1;
            list.push( {
                id: maxId + 1,
                title: 'Title',
                description: 'Description'
            } )
            setList( [...list] )
        }
    }

    /**
     * 
     * @param {} value 
     * data html string used for description of the component
     */
    const eventHandlers = ( value, i ) => {
        list[ i ][ 'description' ] = value
        setList( [...list] )
    }

    const handleMedia = ( data, index) => {
        try {
            if ( data ) {
                const updatedList = [...list];
                updatedList[index] = { ...updatedList[index], imgUrl: data.name };
                handleCeAssets(list[ index ]?.imgUrl,data.name,{ panelList:[...updatedList]});
                setList( [...updatedList] );
                handleProperties( { panelList: [...updatedList] } );
            }
        }
        catch ( e ) {
            console.log( e );
        }
    }
    const imageStyleChange = (e, index) => {
        let lists = [ ...list]
        if(!lists[ index ]["styles"]){
            lists[ index ]["styles"]={}
        }
        lists[ index ]["styles"][e.target.name] =  e?.target?.value || "";
        setList( [...lists] )
        e.stopPropagation();
      };

    const handleRadioChange = ( value, i ) => {
        let list = [ ...panelList ]
        list[ i ][ 'imgPosition' ] = value
        setList( [...list] )
    }

    const handleTextChange = ( value, i ) => {
        setList((prev)=>{
            prev[i]['textPosition']=value
            return [...prev]
        })
    }

      /**
   * Updates the list of accordion items
   * @param {Event} e - Event object
   * @param {Array} list - Updated list of items
   */
  const handleUpdateAccList = (e, list) => {
    setList([...list]);
  }

    return (
        <div className='accordion-prop-container'>
            <div className={ `card-prop-main-ui acccordion-item-list` }>
            <div className="acccordion-item-switch"><FormControlLabel label={'Allow reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
            <DndProvider backend={HTML5Backend}>
                {
                    list && ( list.length > 0 ) && list.map( ( el, index ) => {
                        return<AccordionItem
                        className='inner-summary'
                        key={index}
                        name={`${index+1}. `}
                        index={index}
                        el={el}
                        data={list}
                        listUpdate={handleUpdateAccList}
                        expanded={expanded}
                        handleAccordionChange={handleChange}
                        isDrag={drag}
                        summary={{delete: { min: 1 }, title: { name: 'title' }}}
                        dynamicDetailsComponent={<>
                         { ACLHelper.isFeatureEnabled( 4, 2 ) &&
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                                {/* <FormLabel>Upload Image</FormLabel> */}
                                                <UploaderPanel accept={ 'image' } 
                                                fileName={list[index]?.imgUrl}   
                                                name={ `${ id }_${ Datahelper.getTime() }-${ index + 1 }` } 
                                                setUploadedData={(e)=>handleMedia(e,index)} 
                                                styles={{
                                                    position:false,
                                                    color:
                                                    list[index]["styles"]?.color ||
                                                      "rgba(0, 0, 0, 0)",
                                                  }}
                                                  bgChange={(e) => imageStyleChange(e, index)}
                                                  pChange={(e) => imageStyleChange(e, index)}
                                                />
                                            </div>
                                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="Center"
                                                    name="radio-buttons-group"
                                                    className='row-radio-group'
                                                    value={el?.imagePosition}
                                                >
                                                    <p className='label' id="demo-radio-buttons-group-label"> Align Image </p>
                                                    <FormControlLabel className='radio-chk' value="Left" control={
                                                        <Radio  onChange={ () => handleRadioChange( 'flex-start', index ) } /> }
                                                        label="Left" />
                                                    <FormControlLabel className='radio-chk' value="Center" control={
                                                        <Radio  onChange={ () => handleRadioChange( 'center', index ) } /> }
                                                        label="Center" />

                                                    <FormControlLabel className='radio-chk' value="Right" control={
                                                        <Radio onChange={ () => handleRadioChange( 'flex-end', index ) } /> }
                                                        label="Right" />
                                                </RadioGroup>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="row"
                                                    name="radio-buttons-group"
                                                    className='row-radio-group'
                                                    value={el?.textPosition}
                                                >
                                                    <p className='label' id="demo-radio-buttons-group-label"> Align Text </p>
                                                    <FormControlLabel className='radio-chk' value="row" control={
                                                        <Radio  onChange={ () => handleTextChange( 'row', index ) } /> }
                                                        label="Row" />
                                                       <FormControlLabel className='radio-chk' value="column" control={
                                                        <Radio  onChange={ () => handleTextChange( 'column', index ) } /> }
                                                        label="Colum" />  
                                                        
                                                    </RadioGroup>
                                            </div>

                                        </div>
                                }

                            <p className="description-text label"> Description </p>
                            {expanded === index  && <RichTextEditor data={ el.description } eventHandler={ ( e ) => eventHandlers( e, index ) } />}
                         </>}
                        />
                    } )
                }

            </DndProvider>
                <div className='add-new-slide' title={ 'Add Accordion' } onClick={ addAccordion }><AddCircle className='add-accordion' /> Add New Slide</div>

            </div>
        </div>
    )
}

AccordionPanel.propTypes = {
    /** accordion panel array data list  */
    panelList: PropTypes.array,
    /** function handler used to save the edited props */
    handleProperties: PropTypes.func,
    /** function handler used to update the Assets */
    handleCeAssets : PropTypes.func,
}

export default AccordionPanel
